import React, {useEffect, useState, Fragment} from 'react';
import {connect} from 'react-redux';
import MyTransactionsList from './components/MyTransactionsList';
import * as actions from './MyTransactionsActions';
import MyTransactionDetails from './components/MyTransactionDetails';
import spinner from '../../assets/spinner.svg';
import styled from 'styled-components';

const Spinner = styled.img`
  width: 64px;
  margin: auto;
  margin-top: 50px;
`;

const MyTransactionsContainer = ({
  locations,
  getMyTransactionsList,
  getMyTransactionDetails,
  myTransactionsReducer,
  resetTransactionDetails,
  orderSettingReducer,
}) => {
  const [orderId, setOrderId] = useState(null);
  const {
    myTransactions,
    requestingMyTransactions,
    myTransactionDetails,
  } = myTransactionsReducer;

  const {
    interOrderType,
    interDeliveryDetails,
    interPickUpDetails,
  } = orderSettingReducer;

  useEffect(() => {
    getMyTransactionsList();
  }, []);

  useEffect(() => {
    orderId && getMyTransactionDetails(orderId);
  }, [orderId]);

  const resetMyTransactionDetails = () => {
    setOrderId(null);
    resetTransactionDetails();
  };

  let currency = null;

  const foundLocation =
    myTransactionDetails &&
    locations.find(
      location => location.id === myTransactionDetails.business_id
    );

  if (foundLocation && foundLocation.currency) {
    currency = foundLocation.currency;
  }

  return (
    <Fragment>
      {!orderId ? (
        <MyTransactionsList
          setOrderId={setOrderId}
          myTransactions={myTransactions}
          requestingMyTransactions={requestingMyTransactions}
          orderSettingReducer={orderSettingReducer}
        />
      ) : (
        <MyTransactionDetails
          myTransactions={myTransactions}
          myTransactionDetails={
            myTransactionDetails
              ? {
                  ...myTransactionDetails,
                  currency: currency,
                }
              : null
          }
          resetMyTransactionDetails={resetMyTransactionDetails}
          orderSettingReducer={orderSettingReducer}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  orderHistoryReducer: state.orderHistoryReducer,
  interPickUpDetails: state.orderSettingReducer.interPickUpDetails,
  locations: state.locationsReducer.locations,
  myTransactionsReducer: state.myTransactionsReducer,
  orderSettingReducer: state.orderSettingReducer,
});

export default connect(mapStateToProps, actions)(MyTransactionsContainer);
