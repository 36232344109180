import React, { Fragment } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  Title1,
  Title2,
  Title3,
  Body,
  Headline,
} from "../../../components/Typography";
import appConfig from "../../../../app-config";
import IQFoodEmptyTransactions from "./ClientEmptyTransactions/IQFoodEmptyTransactions";

const NoOrders = styled.div`
  margin: 15px 10px;
  padding: 30px 30px;
  max-width: 450px;
  border-radius: 16px;
  display: grid;
  grid-gap: 12px;
  text-align: center;
  width: 1080px;
  color: ${(props) => props.theme.darkGray};
`;

const NoOrdersTitle = styled(Title2)`
font-size:24px;
  color: ${(props) => props.theme.darkGray};
`;

const EmptyTransactions = ({
  myTransactions,
  setOrderId,
  requestingMyTransactions,
}) => {
  if (appConfig.appSource === "iQ") {
    return <IQFoodEmptyTransactions />;
  }
  return (
    <NoOrders>
      <i className="fal fa-receipt" style={{ fontSize: "45px" }} />
      <NoOrdersTitle>Order History Is Empty</NoOrdersTitle>
      <Body color={`${(props) => props.theme.darkGray}`}>
        Your past transactions will display here{" "}
      </Body>
    </NoOrders>
  );
};

export default EmptyTransactions;
