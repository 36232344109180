import React, {Fragment} from 'react';
import styled from 'styled-components';
import {
  Title1,
  Title2,
  Title3,
  Body,
  Headline,
  Subhead,
} from '../../../../components/Typography';
import appConfig from '../../../../../app-config';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 85px 1fr;
  grid-gap: 32px;
  align-items: center;
  text-align: center;

  margin-top: 81px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  align-items: center;
  text-align: center;
`;

const NoAddresses = styled.div`
  // margin: 15px 10px;
  padding: 30px 30px;
  border: dashed 2px #979797;
  border-radius: 16px;
  display: grid;
  grid-gap: 12px;
  text-align: center;
  color: ${props => props.theme.darkGray};
`;

const NoTransactionsTitle = styled(Title2)`
  color: #58595b;
`;

const IQFoodEmptyTransactions = ({}) => {
  return (
    <Wrapper>
      <img
        src="https://s3.amazonaws.com/assets.smoothpay.com/web/iq-foods-assets/recent-orders-icons-1%403x.png"
        width="85"
        style={{margin: 'auto'}}
      />
      <ContentWrapper>
        <NoTransactionsTitle>
          For When You Need To Dig Up Receipts
        </NoTransactionsTitle>
        <Body color="#58595b">Your full order history will appear here</Body>
      </ContentWrapper>
    </Wrapper>
  );
};

export default IQFoodEmptyTransactions;
