import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import moment from 'moment';
import { Title1, Headline, Body } from '../../../components/Typography';
import appConfig from '../../../../app-config';
import spinner from '../../../assets/spinner.svg';

import OrderLocationDetails from './OrderLocationDetails';
import OrderComboDetails from './OrderComboDetails';
import OrderDetails from './OrderDetails';
import OrderReceipt from './OrderReceipt';

const Spinner = styled.img`
  width: 64px;
  margin: 50px 0 0 100px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  @media screen and (max-width: 768px) {
    margin: 10px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    padding: 0 10px;
  }
  @media screen and (max-width: 360px) {
    margin: 0;
    flex-direction: column;
  }
`;

const Content = styled.div`
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};

  @media screen and (max-width: 767px) {
    margin: 10px 0 0 0;
    align-items: center;
    width: 100%;
  }
  @media screen and (max-width: 360px) {
    margin: 10px 0 0 0;
    align-items: center;
    width: 100%;
  }
`;

const TitleWrapper = styled(Title1)`
  width: 200px;
  padding-bottom: 10px;
  font-weight: 600;
  &:after {
    content: '';
    display: block;
    width: calc(100%+2px);
    padding-top: 2px;
    border-bottom: ${(props) =>
      props.theme.categoriesBarSelectedBorderColorWidth};
  }
  @media screen and (max-width: 768px) {
    width: 400px;
  }
`;

const OrderDetailContent = styled.div`
  display: grid;
  grid-gap: 23.5px;
`;

const HrLine = styled.hr`
  border: none;
  border-top: 2px dashed ${(props) => props.theme.gray};
  background: ${(props) => props.theme.white};
  margin: auto 0;
`;

const OrderedProducts = styled.div`
  display: grid;
  grid-gap: 24px;
`;

const Refund = styled.span`
  font-size: 24px;
  color: #01790d;
`;

const BackButton = styled.div`
  margin-bottom: 20px;
  margin-top: 15px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
  align-items: center;
  cursor: pointer;
`;

const OrderHistorySpan = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:hover {
    color: ${(props) => props.theme.headerHoverColor};
    transition: all 0.25s ease;
  }
`;

const BackText = styled.div`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: ${(props) => props.theme.backTextColorHover};
    transition: all 0.25s ease;
  }
`;

const BodyWrapper = styled.div`
  margin: 0 10% 0 10%;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

const MyTransactionDetails = ({
  myTransactionDetails,
  resetMyTransactionDetails,
  orderSettingReducer,
  myTransactions,
}) => {
  return (
    <Wrapper>
      <Content margin={'5px 70px 10px 0'}>
        <BackButton id="MyTransactions-BackButton">
          <i className="far fa-chevron-left" style={{ fontSize: '16px' }} />
          <BackText onClick={() => resetMyTransactionDetails()}>
            <Body>Back to Order History</Body>
          </BackText>
        </BackButton>
        {myTransactionDetails && (
          <TitleWrapper>
            {' '}
            Purchase on{' '}
            {moment(
              myTransactionDetails.order_created_at,
              'YYYY-MM-DD HH:mm:ss'
            ).format('MMM DD, YYYY')}
          </TitleWrapper>
        )}
        {myTransactionDetails &&
          myTransactionDetails.transactions[0].type === 'refund' && (
            <Refund id="MyTransactions-Refund">Refund</Refund>
          )}
      </Content>
      <Content width={'400px'} margin={'20px 0'}>
        {myTransactionDetails && (
          <OrderDetailContent>
            <OrderLocationDetails
              myTransactions={myTransactions}
              myTransactionDetails={myTransactionDetails}
              selectedChildLocation={orderSettingReducer.selectedChild}
            />
            <OrderedProducts>
              {myTransactionDetails.config_products.length > 0 ? (
                myTransactionDetails.combos.length > 0 ? (
                  <React.Fragment>
                    <OrderComboDetails
                      myTransactionDetails={myTransactionDetails}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <OrderDetails myTransactionDetails={myTransactionDetails} />
                  </React.Fragment>
                )
              ) : (
                <div> Gift Card Purchase</div>
              )}
            </OrderedProducts>
            <HrLine />
            <OrderReceipt
              myTransactionDetails={myTransactionDetails}
              myTransactions={myTransactions}
              appConfig={appConfig}
            />
          </OrderDetailContent>
        )}
        {!myTransactionDetails && <Spinner src={spinner} />}
      </Content>
    </Wrapper>
  );
};

export default MyTransactionDetails;
