import React from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import {Body} from '../../../components/Typography';

const ProductNamePrice = styled.div`
  display: grid;
  grid-template-columns: 9fr 2fr;
  grid-gap: 8px;
  margin-bottom: 8px;
`;

const Options = styled.div`
  margin-left: 10px;
`;

const Option = styled.div`
  display: grid;
  grid-template-columns: 9fr 2fr;
  grid-gap: 8px;
  color: ${props => props.theme.darkGray};

  p::first-letter {
    text-transform: capitalize;
  }
`;

function OrderDetails(props) {
  const {myTransactionDetails} = props;

  return myTransactionDetails.config_products.map((product, index) => (
    <div key={index}>
      <ProductNamePrice id="MyTransactions-ProductNamePrice">
        <Body style={{textTransform: 'capitalize', fontWeight: 600}}>
          {product.name}
          {product.quantity > 1 && (
            <span style={{textTransform: 'lowercase'}}>
              {' ('}x{product.quantity}
              {')'}
            </span>
          )}
        </Body>
        <Body style={{textAlign: 'right', fontWeight: 600}}>
          {numeral(product.price).format('$0.00')}
        </Body>
      </ProductNamePrice>
      <Options id="MyTransactions-Options">
        {product.options &&
          product.options
            .map((option, index) =>
              option.sub_options && option.sub_options.length
                ? option.sub_options.map(subOption =>
                    subOption.items.map(item => ({
                      name: `${option.name} - ${subOption.name} - ${item.name}`,
                      value:
                        subOption.included_pick === 0
                          ? item.price
                          : item.additional_price,
                    }))
                  )
                : option.items.map(item => ({
                    name: `${option.name} - ${item.name}`,
                    value:
                      option.included_pick === 0
                        ? item.price
                        : item.additional_price,
                  }))
            )
            .flat()
            .map((option, index) => (
              <Option key={index}>
                <Body color={props => props.theme.darkGray}>{option.name}</Body>
                <Body
                  color={props => props.theme.darkGray}
                  style={{textAlign: 'right'}}
                >
                  {numeral(option.value).format('$0.00')}
                </Body>
              </Option>
            ))}
      </Options>
    </div>
  ));
}

export default OrderDetails;
