import React from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import { Headline, Body } from '../../../components/Typography';

const ReceiptDiv = styled.div`
  display: grid;
  grid-gap: 4px;
`;

const ReceiptRow = styled.div`
  display: grid;
  grid-template-columns: 9fr 2fr;
  grid-gap: 8px;
  span {
    text-transform: capitalize;
  }
`;

const HrLine = styled.hr`
  border: none;
  border-top: 2px dashed ${(props) => props.theme.gray};
  background: ${(props) => props.theme.white};
  margin: auto 0;
`;

function OrderReceipt(props) {
  const { myTransactionDetails, appConfig, myTransactions } = props;
  const foundTransaction = myTransactions.find(
    (transaction) => transaction.order_id == myTransactionDetails.order_id
  );

  const isRefundedOrder =
    foundTransaction && foundTransaction.type === 'refund';

  const currency = myTransactionDetails.currency
    ? myTransactionDetails.currency
    : null;

  return (
    <React.Fragment>
      <ReceiptDiv>
        <ReceiptRow>
          <Body id="MyTransactions-Subtotal">Subtotal</Body>
          <Body style={{ textAlign: 'right' }}>
            {numeral(myTransactionDetails.subtotal).format('$0.00')}
          </Body>
        </ReceiptRow>
        <ReceiptRow>
          <Body id="MyTransactions-Discount">Discount</Body>
          <Body style={{ textAlign: 'right' }}>
            {numeral(myTransactionDetails.total_credit_applied).format('$0.00')}
          </Body>
        </ReceiptRow>
        <ReceiptRow>
          <Body id="MyTransactions-Tip">Tip</Body>
          <Body style={{ textAlign: 'right' }}>
            {numeral(myTransactionDetails.tip).format('$0.00')}
          </Body>
        </ReceiptRow>
        {myTransactionDetails.service_fees &&
        myTransactionDetails.service_fees.length > 0
          ? myTransactionDetails.service_fees.map((serviceFee, i) => (
              <ReceiptRow key={i}>
                <Body id={`PriceBreakDown-${serviceFee.display_name}`}>
                  {serviceFee.display_name}
                </Body>
                <Body
                  style={{ textAlign: 'right' }}
                  id={`PriceBreakDown-${serviceFee.display_name}-Amount`}
                >
                  {numeral(serviceFee.amount).format('$0.00')}
                </Body>
              </ReceiptRow>
            ))
          : null}
        {/* {myTransactionDetails.total_summary &&
        myTransactionDetails.total_summary.fees ? (
          <ReceiptRow>
            <Body id="MyTransactions-DeliveryFee">Delivery Fee</Body>
            <Body style={{textAlign: 'right'}}>
              {numeral(myTransactionDetails.total_summary.fees).format('$0.00')}
            </Body>
          </ReceiptRow>
        ) : null} */}
        <ReceiptRow>
          <Body id="MyTransactions-Tax">Tax</Body>
          <Body style={{ textAlign: 'right' }}>
            {numeral(myTransactionDetails.tax).format('$0.00')}
          </Body>
        </ReceiptRow>
        <ReceiptRow>
          <Headline id="MyTransactions-Total">Total</Headline>
          <Headline style={{ textAlign: 'right' }}>
            {numeral(myTransactionDetails.total).format('$0.00')}
          </Headline>
        </ReceiptRow>
        <div style={{ textAlign: 'right' }}>
          {currency ? `All prices are in ${currency}` : null}
        </div>
      </ReceiptDiv>
      <HrLine />
      {myTransactionDetails.transactions && (
        <ReceiptDiv id="MyTransactions-Receipt">
          {myTransactionDetails.transactions[0] &&
            myTransactionDetails.transactions[0].payment_method && (
              <ReceiptRow>
                <Body
                  id="MyTransactions-PaidWith"
                  style={{ color: isRefundedOrder && 'green' }}
                >
                  {isRefundedOrder ? (
                    <span>Refunded to: </span>
                  ) : (
                    <span>Paid with: </span>
                  )}
                  <span>
                    {myTransactionDetails.transactions[0].payment_method
                      .type === 'paypal'
                      ? `PayPal \r (${myTransactionDetails.transactions[0].payment_method.details.email})`
                      : myTransactionDetails.transactions[0].payment_method
                          .details.card_type}
                  </span>{' '}
                  {myTransactionDetails.transactions[0].payment_method.type !==
                    'paypal' &&
                    `${myTransactionDetails.transactions[0].payment_method.details.last_four}`}
                </Body>
              </ReceiptRow>
            )}
          {appConfig.isLoyaltyEnabled &&
          myTransactionDetails.loyalty_earned &&
          appConfig.appSource !== 'iQ' ? (
            <ReceiptRow>
              <Body id="MyTransactions-PointsEarned">
                Points earned: {myTransactionDetails.loyalty_earned}
              </Body>
            </ReceiptRow>
          ) : null}
        </ReceiptDiv>
      )}
    </React.Fragment>
  );
}

export default OrderReceipt;
