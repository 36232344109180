import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Headline, Body} from '../../../components/Typography';
import {flatMap, map} from 'lodash';

const LocationDetail = styled.div`
  display: grid;
  grid-gap: 4px;
`;

const HrLine = styled.hr`
  border: none;
  border-top: 2px dashed ${props => props.theme.gray};
  background: ${props => props.theme.white};
  margin: auto 0;
`;

function OrderLocationDetails(props) {
  const {myTransactionDetails, selectedChildLocation, myTransactions} = props;
  const foundTransaction = myTransactions.find(
    transaction => transaction.order_id == myTransactionDetails.order_id
  );

  const getDropOffAddressArray = address =>
    address.reduce((arr, curAddress) => {
      arr = [...arr, curAddress];
      if (curAddress.option) {
        return [...arr, ...getDropOffAddressArray(curAddress.option)];
      } else {
        return arr;
      }
    }, []);

  let dropOffAddressArray = [];

  if (
    myTransactionDetails.order_type === 'drop_off' &&
    myTransactionDetails.drop_off_address
  ) {
    dropOffAddressArray = [
      myTransactionDetails.drop_off_address,
      ...getDropOffAddressArray(myTransactionDetails.drop_off_address.option),
    ];
  }

  return (
    <React.Fragment>
      <LocationDetail>
        <Headline
          id="MyTransactions-LocationHeadline"
          style={{marginBottom: '4px', fontWeight: 'bold'}}
        >
          {myTransactionDetails.order_type == 'pickup'
            ? 'Picked up from:'
            : myTransactionDetails.order_type == 'delivery'
            ? 'Delivered to:'
            : myTransactionDetails.order_type == 'drop_off'
            ? 'Dropping off to:'
            : null}
        </Headline>
        {myTransactionDetails.order_type == 'pickup' && (
          <Body>
            {selectedChildLocation ? (
              <div>
                {selectedChildLocation.name}
                <div>{myTransactionDetails.business_detail.display_name}</div>
              </div>
            ) : (
              myTransactionDetails.business_detail.display_name
            )}
          </Body>
        )}
        {myTransactionDetails.order_type == 'pickup' &&
          myTransactionDetails.business_address && (
            <Body>{`${myTransactionDetails.business_address.line1} 
                    ${myTransactionDetails.business_address.line2} 
                    ${myTransactionDetails.business_address.line3} 
                    ${myTransactionDetails.business_address.city},
                     ${myTransactionDetails.business_address.state}, 
                     ${myTransactionDetails.business_address.zip}`}</Body>
          )}

        {myTransactionDetails.order_type == 'delivery' &&
          myTransactionDetails.customer_address && (
            <Body>{`${myTransactionDetails.customer_address.line1} 
                    ${myTransactionDetails.customer_address.line2} 
                    ${myTransactionDetails.customer_address.line3} 
                    ${myTransactionDetails.customer_address.city}, 
                    ${myTransactionDetails.customer_address.state}, 
                    ${myTransactionDetails.customer_address.zip}`}</Body>
          )}
        {myTransactionDetails.order_type == 'drop_off' && (
          <Body>
            {dropOffAddressArray.map((address, i) => (
              <div key={i}>
                {address.label}: {address.value}
              </div>
            ))}
          </Body>
        )}
        <Headline style={{marginBottom: '4px', fontWeight: 'bold'}}>
          {myTransactionDetails.order_type == 'delivery'
            ? 'Ordered from:'
            : null}
        </Headline>
        {myTransactionDetails.order_type == 'delivery' &&
          myTransactionDetails.business_detail.display_name && (
            <Body>{`${myTransactionDetails.business_detail.display_name}`}</Body>
          )}
        <Body>
          On{' '}
          {moment(
            myTransactionDetails.order_date,
            'YYYY-MM-DD HH:mm:ss'
          ).format('MMM DD, YYYY')}{' '}
          at{' '}
          {moment(
            myTransactionDetails.order_date,
            'YYYY-MM-DD HH:mm:ss'
          ).format('hh:mm A')}
        </Body>
        <Body>
          Order ID: {myTransactionDetails.order_id}
          {foundTransaction && foundTransaction.type === 'refund' && (
            <span style={{color: 'green'}}> - Refunded</span>
          )}
        </Body>
      </LocationDetail>
      <HrLine />
    </React.Fragment>
  );
}

export default OrderLocationDetails;
