import React from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import {Body} from '../../../components/Typography';

const ProductNamePrice = styled.div`
  display: grid;
  grid-template-columns: 9fr 2fr;
  grid-gap: 8px;
  margin-bottom: 8px;
`;

const Options = styled.div`
  margin-left: 10px;
`;

const Option = styled.div`
  display: grid;
  grid-template-columns: 9fr 2fr;
  grid-gap: 8px;
  color: ${props => props.theme.darkGray};

  p::first-letter {
    text-transform: capitalize;
  }
`;

function OrderComboDetails(props) {
  const {myTransactionDetails} = props;

  const RenderComboDetails = () => {
    const transactionDetails = [];
    let lastIndex = 0;

    myTransactionDetails.combos.map((combo, index) => {
      let comboSize = combo.combo_item_groups.length;
      let comboProducts = combo.combo_item_groups
        .map(group => {
          const selectedProduct = group.combo_items.find(
            groupProduct => groupProduct.is_selected
          );
          if (selectedProduct) {
            return selectedProduct.product;
          }
        })
        .filter(product => product);

      transactionDetails.push(
        <div>
          <ProductNamePrice>
            <Body style={{textTransform: 'capitalize'}}>
              <div style={{fontWeight: 600}}>{combo.name}</div>
            </Body>
            <Body style={{textAlign: 'right'}}>
              <div style={{fontWeight: 600}}>
                {numeral(combo.price.amount).format('$0.00')}
              </div>
            </Body>
          </ProductNamePrice>
          <div>
            {comboProducts.map((product, index) => {
              return (
                <div key={index}>
                  <ProductNamePrice id="MyTransactions-ComboProductNamePrice">
                    <Body style={{textTransform: 'capitalize'}}>
                      {product.name} - {product.variant_name}
                    </Body>
                    {/* <Body style={{textAlign: 'right'}}>
                      {numeral(product.price).format('$0.00')}
                    </Body> */}
                  </ProductNamePrice>
                </div>
              );
            })}
          </div>
        </div>
      );
    });

    return transactionDetails;
  };

  return (
    <React.Fragment>
      <RenderComboDetails />
      {myTransactionDetails.products.map((product, index) => (
        <div key={index}>
          <ProductNamePrice>
            <Body style={{textTransform: 'capitalize', fontWeight: 600}}>
              {product.name}
            </Body>
            <Body style={{textAlign: 'right', fontWeight: 600}}>
              {numeral(product.price).format('$0.00')}
            </Body>
          </ProductNamePrice>
          <Options>
            {product.options &&
              product.options
                .map((option, index) =>
                  option.sub_options && option.sub_options.length
                    ? option.sub_options.map(subOption =>
                        subOption.items.map(item => ({
                          name: `${option.name} - ${subOption.name} - ${item.name}`,
                          value:
                            subOption.included_pick === 0
                              ? item.price
                              : item.additional_price,
                        }))
                      )
                    : option.items.map(item => ({
                        name: `${option.name} - ${item.name}`,
                        value:
                          option.included_pick === 0
                            ? item.price
                            : item.additional_price,
                      }))
                )
                .flat()
                .map((option, index) => (
                  <Option key={index}>
                    <Body color={props => props.theme.darkGray}>
                      {option.name}
                    </Body>
                    <Body
                      color={props => props.theme.darkGray}
                      style={{textAlign: 'right'}}
                    >
                      {numeral(option.value).format('$0.00')}
                    </Body>
                  </Option>
                ))}
          </Options>
        </div>
      ))}
    </React.Fragment>
  );
}

export default OrderComboDetails;
