import React, {Fragment} from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {
  Title1,
  Title2,
  Title3,
  Body,
  Headline,
} from '../../../components/Typography';
import spinner from '../../../assets/spinner.svg';
import moment from 'moment';
import numeral from 'numeral';
import appConfig from '../../../../app-config';
import EmptyTransactions from './EmptyTransactions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  // margin: 0 20px;

  // @media (max-width: 1800px) {
  //   margin: 0 10%;
  // }

  margin-top: 15px;

  @media screen and (max-width: 768px) {
    margin: 10px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 360px) {
    margin: 0;
    flex-direction: column;
  }
`;

const Content = styled.div`
    width: ${props => props.width};
    margin: ${props => props.margin};

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }
    @media screen and (max-width: 767px) {
      margin:10px 0 0 0;
      align-items: center;
      width:100%;

    @media screen and (max-width: 360px) {
      margin: 10px 0 0 0;
      align-items: center;
      width:100%;
    }
`;

const TitleWrapper = styled(Title1)`
  padding-bottom: 10px;
  text-transform: ${props => props.theme.pageTitleTextTransform};
  width: max-content;
  font-weight: 600;

  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${props => props.categoriesBarSelectedBorderColorWidth} solid
      ${props => props.theme.categoriesBarSelectedBorderColor};
  }
`;

const NoOrdersTitle = styled(Title2)`
  color: ${props => props.theme.darkGray};
`;

const Spinner = styled.img`
  width: 64px;

  margin: auto;
  margin-top: 50px;
`;

const OrderWrapper = styled.div`
  display: grid;
  // grid-gap: 20px;
`;

const OrderCard = styled.div`
  display: grid;
  grid-gap: 14px;
  cursor: pointer;
  padding: 8px;
  border-bottom: 1px solid ${props => props.theme.lightGray};
  text-decoration: none;
  color: inherit;

  &:hover {
    background: ${props => props.theme.orderCardBackgroundColorHover};
    transition: all 0.25s ease;
  }
`;

const LocationAndButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
  align-items: center;
`;

const SubTitle = styled.div`
  color: ${props => props.theme.darkGray};
`;

const ChevronRight = styled.i`
  // color: ${props => props.theme.darkGray};
  font-size: 16px;
`;

const OrderInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Refund = styled.span`
  font-size: 20px;
  color: #01790d;
`;

const NoOrders = styled.div`
  // margin: 15px 10px;
  padding: 30px 30px;
  border: dashed 2px #979797;
  border-radius: 16px;
  display: grid;
  grid-gap: 12px;
  text-align: center;
  color: ${props => props.theme.darkGray};
`;

const BodyWrapper = styled.div`
  margin: 0 10%;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const MyTransactionsList = ({
  myTransactions,
  setOrderId,
  requestingMyTransactions,
  orderSettingReducer,
}) => {
  const {selectedChild} = orderSettingReducer;
  return (
    <Wrapper>
      <Content margin={'0 70px 10px 0'}>
        <TitleWrapper id="MyTransactions-OrderHistoryTitle">
          {' '}
          Order History
        </TitleWrapper>
      </Content>
      <Content
        width={
          !requestingMyTransactions && !myTransactions.length ? '100%' : '400px'
        }
      >
        <OrderWrapper>
          {requestingMyTransactions && <Spinner src={spinner} />}
          {!requestingMyTransactions && !myTransactions.length ? (
            <EmptyTransactions />
          ) : (
            myTransactions.map((transaction, index) => {
              return (
                <OrderCard
                  key={index}
                  onClick={() => setOrderId(transaction.order_id)}
                >
                  <LocationAndButton>
                    <div>
                      <Body
                        id="MyTransactions-PurchaseFrom"
                        color={props => props.theme.darkGray}
                        style={{marginBottom: '5px'}}
                      >
                        Purchase from:
                      </Body>
                      <Title3>
                        {transaction.business_detail
                          ? transaction.business_detail.display_name
                          : null}
                        {transaction.type === 'refund' && (
                          <Refund id="MyTransactions-Refund"> - Refund</Refund>
                        )}
                      </Title3>
                    </div>
                    <ChevronRight className="far fa-chevron-right" />
                  </LocationAndButton>
                  <OrderInfo>
                    <div>
                      <Body
                        id="MyTransactions-Date"
                        color={props => props.theme.darkGray}
                      >
                        Date:
                      </Body>
                      <Body id="MyTransactions-CreatedAt">
                        {moment(
                          transaction.created_at,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format('MMM DD, YYYY')}
                      </Body>
                    </div>
                    <div>
                      <Body
                        id="MyTransactions-Total"
                        color={props => props.theme.darkGray}
                      >
                        Total:
                      </Body>
                      <Body id="MyTransactions-Amount">
                        {numeral(transaction.amount).format('$0.00')}
                      </Body>
                    </div>
                  </OrderInfo>
                </OrderCard>
              );
            })
          )}
        </OrderWrapper>
      </Content>
    </Wrapper>
  );
};

export default MyTransactionsList;
