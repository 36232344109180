import React from 'react';
import {connect} from 'react-redux';
import Page404 from './404';
import MyTransactionsContainer from '../modules/MyTransactions/MyTransactionsContainer';

const MyTransactions = ({isAuthenticated}) => {
  if (isAuthenticated) {
    return <MyTransactionsContainer />;
  } else {
    return <Page404 />;
  }
};

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});

export default connect(mapStateToProps)(MyTransactions);
